import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useSpring, config } from "react-spring"
import styled from "styled-components"

import { Subheading, StyledLink } from "./typography"

const Container = styled.footer`
  width: 100%;
  width: calc(100% - 30px);
  height: auto;
  padding: 30px 15px 90px;

  display: grid;
  /* grid-template-rows: auto; */
  grid-template-columns: repeat(6, 1fr);
  column-gap: 9px;
  row-gap: 30px;
  background-color: hsl(0, 0%, 5%);
  color: white;

  font-size: small;

  & > section > h2 {
    margin-bottom: 5px;
    color: white;
  }

  & > :last-child {
    display: none;
  }

  @media screen and (min-width: 768px) {
    align-items: end;
    row-gap: 20px;
    padding-bottom: 20px;

    * {
      color: white;
    }

    a {
      grid-row: 1;
      grid-column: 2 / span 4;
      font-size: medium;
    }

    & > :last-child {
      display: unset;
      grid-row: 2;
      grid-column: 1 / span 6;
      justify-self: center;
      font-size: medium;
    }
  }

  @media screen and (min-width: 992px) {
    width: calc(100% - 60px);
    padding: 15px 30px;

    & > :last-child {
      grid-row: 1;
      grid-column: 1 / span 6;
      justify-self: end;
      align-self: start;
      margin-right: 66px;
      margin-top: 15px;
    }
  }
`

const FooterLinks = styled.div`
  display: none;

  @media screen and (min-width: 768px) {
    grid-row: 1;
    grid-column: 1 / span 6;

    display: flex;
    justify-content: center;

    & > * {
      margin-right: 30px;
    }

    & > :last-child {
      margin-right: 0;
    }
  }

  @media screen and (min-width: 992px) {
    justify-self: start;
    align-self: start;
    margin-top: 15px;
  }
`

const Logo = styled.img`
  grid-row: 1;
  grid-column: 1 / span 6;

  /* width: 109px;
  height: 29px; */

  width: 190px;
  height: 52px;

  filter: invert();

  @media screen and (min-width: 768px) {
    display: none;
  }
`

const ScrollToTopButton = styled.button`
  grid-row: 1;
  grid-column: 5 / span 2;
  align-self: center;
  justify-self: end;
  z-index: 10;
  margin-right: 10px;

  width: 44px;
  height: 44px;

  & > svg {
    & > :first-child {
      fill: hsla(0, 0%, 100%, 0.1);
    }

    & > :last-child {
      fill: none;
      stroke: hsla(0, 0%, 100%, 1);
    }
  }

  @media screen and (min-width: 768px) {
    margin-right: 0;
    justify-self: center;
    grid-column: 6;
    grid-row: 1 / span 2;
  }

  @media screen and (min-width: 992px) {
    grid-row: 1;
    justify-self: end;
  }
`

const ContactUsSection = styled.section`
  grid-column: 1 / span 6;
  display: grid;

  & > div {
    width: fit-content;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
`

const ContactMethod = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: auto auto 1fr;
  column-gap: 8px;
  /* align-items: center; */
  /* align-content: center; */

  & > * {
    grid-row: 1;
  }

  & > img {
    filter: brightness(100);
  }

  & > :last-child {
    justify-self: end;
    min-height: 24px;
  }
`

const AddressSection = styled.section`
  grid-column: 1 / span 3;

  @media screen and (min-width: 768px) {
    display: none;
  }
`

const OfficeHoursSection = styled.section`
  grid-column: 4 / span 3;
  display: grid;

  & > :first-child {
    width: 100%;
    grid-column: 1 / span 2;
    grid-row: 1;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
`

const Navigation = () => {
  const {
    contentfulSiteData: {
      paradiseLogo: {
        description: paradiseLogoDescription,
        file: { url: paradiseLogoUrl },
      },
    },
    contentfulContactInformation: {
      contactMethods,
      address: { address },
      hoursLabels: { hoursLabels },
      hoursTimes: { hoursTimes },
    },
  } = useStaticQuery(
    graphql`
      query FooterQuery {
        contentfulSiteData {
          paradiseLogo {
            description
            file {
              url
            }
          }
        }
        contentfulContactInformation {
          contactMethods {
            label
            text
            linkAddress
          }
          address {
            address
          }
          hoursLabels {
            hoursLabels
          }
          hoursTimes {
            hoursTimes
          }
        }
      }
    `
  )

  const [, setY] = useSpring(() => ({
    y: typeof window !== "undefined" ? window.scrollY : 0,
    config: config.default,
  }))

  return (
    <Container>
      <Logo src={paradiseLogoUrl} alt={paradiseLogoDescription} />
      <ScrollToTopButton
        aria-label="Scroll to top button"
        onClick={() => {
          setY({
            y: 0,
            reset: true,
            from: { y: window.scrollY },
            // eslint-disable-next-line react/prop-types
            onFrame: (props) => window.scroll(0, props.y),
          })
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
          <path d="M40,44H4c-2.2,0-4-1.8-4-4V4c0-2.2,1.8-4,4-4h36c2.2,0,4,1.8,4,4v36C44,42.2,42.2,44,40,44z" />
          <path d="M16,25l6-6l6,6" />
        </svg>
      </ScrollToTopButton>
      <ContactUsSection>
        <Subheading>Contact Us</Subheading>
        {contactMethods.map(({ label, text, linkAddress }, index) => (
          <ContactMethod key={index}>
            <p>{label} /</p>
            {linkAddress ? <a href={linkAddress}>{text}</a> : <p>{text}</p>}
          </ContactMethod>
        ))}
      </ContactUsSection>
      <AddressSection>
        <Subheading>Visit Us</Subheading>
        {address
          ? address.split("\n").map((line, index) => <p key={index}>{line}</p>)
          : null}
      </AddressSection>
      <OfficeHoursSection>
        <Subheading>Office Hours</Subheading>
        {hoursLabels
          ? hoursLabels.split("\n").map((line, index) => (
              <p
                className="hours-label"
                key={index}
                style={{ gridColumn: "1", gridRow: `${index + 2}` }}
              >
                {line}
              </p>
            ))
          : null}
        {hoursTimes
          ? hoursTimes.split("\n").map((line, index) => (
              <p
                className="hours-time"
                key={index}
                style={{ gridColumn: "2", gridRow: `${index + 2}` }}
              >
                {line}
              </p>
            ))
          : null}
      </OfficeHoursSection>
      <FooterLinks>
        <StyledLink to={"/privacy-policy/"}>Privacy Policy</StyledLink>
        <StyledLink to={"/terms-and-conditions/"}>
          Terms &amp; Conditions
        </StyledLink>
        <StyledLink to={"/accessibility-statement/"}>Accessibility</StyledLink>
      </FooterLinks>
      <Subheading>
        {`© ${new Date().getFullYear()} • Paradise Banquet and Convention Centre`}
      </Subheading>
    </Container>
  )
}

export default Navigation
