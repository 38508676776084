import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const textColour = `hsl(0, 0%, 20%)`

//
// h1 — Main Headings
//

const HeadingStyle = styled.h1`
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 1.125;
  font-family: "Saol Display", "Times New Roman", Times, serif;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${textColour};
  margin-bottom: 10px;

  @media screen and (min-width: 768px) {
    font-size: 42px;

    & > span {
      white-space: nowrap;
    }
  }
`

export const Heading = ({ children }) => (
  <HeadingStyle>
    {children.toLowerCase() === "paradise banquet hall" ? (
      <>
        {children
          .split(" ")
          .splice(0, children.split(" ").length - 2)
          .join(" ")}{" "}
        <span>
          {children
            .split(" ")
            .splice(children.split(" ").length - 2, children.split(" ").length)
            .join(" ")}
        </span>
      </>
    ) : children.split(" ").length > 3 ? (
      <>
        {children
          .split(" ")
          .splice(0, children.split(" ").length - 2)
          .join(" ")}{" "}
        <span>
          {children
            .split(" ")
            .splice(children.split(" ").length - 2, children.split(" ").length)
            .join(" ")}
        </span>
      </>
    ) : (
      <>{children}</>
    )}
  </HeadingStyle>
)

Heading.propTypes = {
  children: PropTypes.string.isRequired,
}

//
// h2 — Second level of Headings
//

const SubheadingStyle = styled.h2`
  font-style: italic;
  font-variant: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 1.25;
  font-family: "Saol Text", "Times New Roman", Times, serif;
  letter-spacing: 0.04em;
  color: ${textColour};
  margin-bottom: 10px;
`

export const Subheading = ({ children, style }) => (
  <SubheadingStyle style={style}>{children}</SubheadingStyle>
)

Subheading.propTypes = {
  children: PropTypes.string.isRequired,
  style: PropTypes.object,
}

//
// a — Second level of Headings
//

const AnchorStyle = styled(OutboundLink)`
  font-style: italic;
  font-variant: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 1.25;
  font-family: "Saol Text", "Times New Roman", Times, serif;
  letter-spacing: 0.04em;
  color: ${textColour};
  text-decoration: none;
  padding-bottom: 2px;
  border-bottom: 1px solid currentColor;
  width: fit-content;
  transition: opacity 300ms ease-in-out;

  @media screen and (min-width: 992px) {
    :hover,
    :focus {
      opacity: 0.5;
    }
  }
`

const LinkStyle = styled(Link)`
  font-style: italic;
  font-variant: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 1.25;
  font-family: "Saol Text", "Times New Roman", Times, serif;
  letter-spacing: 0.04em;
  color: ${textColour};
  text-decoration: none;
  padding-bottom: 2px;
  border-bottom: 1px solid currentColor;
  width: fit-content;
  transition: opacity 300ms ease-in-out;

  @media screen and (min-width: 992px) {
    :hover,
    :focus {
      opacity: 0.5;
    }
  }
`

export const StyledLink = ({ children, href, to, state }) =>
  to ? (
    <LinkStyle to={to} state={state}>
      {children}
    </LinkStyle>
  ) : href ? (
    <AnchorStyle href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </AnchorStyle>
  ) : null

StyledLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  href: PropTypes.string,
  to: PropTypes.string,
  state: PropTypes.object,
}

//
// Menu h2 — Menu Second Heading
//

const MenuSubheadingStyle = styled.h2`
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-size: 27px;
  line-height: 1.375;
  font-family: "Saol Text", "Times New Roman", Times, serif;
  letter-spacing: 0.01em;
  color: ${textColour};
  margin-top: ${(props) => (props.marginTop ? "45px" : "30px")};
  margin-bottom: 10px;

  & > span {
    white-space: nowrap;
  }
`

export const MenuSubheading = ({ children, marginTop = false, style }) => (
  <MenuSubheadingStyle marginTop={marginTop} style={style}>
    {children
      .split(" ")
      .splice(0, children.split(" ").length - 2)
      .join(" ")}{" "}
    <span>
      {children
        .split(" ")
        .splice(children.split(" ").length - 2, children.split(" ").length)
        .join(" ")}
    </span>
  </MenuSubheadingStyle>
)

MenuSubheading.propTypes = {
  children: PropTypes.string.isRequired,
  marginTop: PropTypes.bool,
  style: PropTypes.object,
}

//
// Menu h3 — Menu Third Heading
//

const MenuThirdHeadingStyle = styled.h3`
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.25;
  font-family: "Sofia Pro", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${textColour};
  margin-top: 15px;
  margin-bottom: 5px;

  & > span {
    white-space: nowrap;
  }
`

export const MenuThirdHeading = ({ children }) => (
  <MenuThirdHeadingStyle>
    {children
      .split(" ")
      .splice(0, children.split(" ").length - 2)
      .join(" ")}{" "}
    <span>
      {children
        .split(" ")
        .splice(children.split(" ").length - 2, children.split(" ").length)
        .join(" ")}
    </span>
  </MenuThirdHeadingStyle>
)

MenuThirdHeading.propTypes = {
  children: PropTypes.string.isRequired,
}

//
// Price — Price Styling
//

const PriceStyle = styled.p`
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  font-size: 35px;
  line-height: 1.25;
  font-family: "Sofia Pro", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
  letter-spacing: 0.01em;
  color: ${textColour};
  margin-bottom: 20px;
`

export const Price = ({ children }) => <PriceStyle>{children}</PriceStyle>

Price.propTypes = {
  children: PropTypes.string.isRequired,
}

//
// h3 — Third level of Headings for Real Weddings
//

const RealWeddingsThirdHeadingStyle = styled.h3`
  font-style: italic;
  font-variant: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.375;
  font-family: "Saol Text", "Times New Roman", Times, serif;
  letter-spacing: 0.04em;
  color: ${textColour};
  margin-bottom: 10px;
`

export const RealWeddingsThirdHeading = ({ children }) => (
  <RealWeddingsThirdHeadingStyle>{children}</RealWeddingsThirdHeadingStyle>
)

RealWeddingsThirdHeading.propTypes = {
  children: PropTypes.string.isRequired,
}

//
// p — Body Copy
//

const CopyStyle = styled.p`
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  font-size: 15.5px;
  line-height: 1.625;
  font-family: "Sofia Pro", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
  letter-spacing: 0.04em;
  color: ${textColour};
  margin-bottom: 5px;

  & > span {
    white-space: nowrap;
  }
`

export const Copy = ({ children }) => (
  <CopyStyle>
    {children
      .split(" ")
      .splice(0, children.split(" ").length - 2)
      .join(" ")}{" "}
    <span>
      {children
        .split(" ")
        .splice(children.split(" ").length - 2, children.split(" ").length)
        .join(" ")}
    </span>
  </CopyStyle>
)

Copy.propTypes = {
  children: PropTypes.string.isRequired,
}

export const StylesPreview = () => (
  <>
    <hr
      style={{
        background: "hsl(0, 0%, 20%)",
        border: "none",
        height: "1px",
        width: "100%",
      }}
    />
    <Heading>Heading</Heading>
    <Subheading>Subheading</Subheading>
    <StyledLink href="https://theworkhouse.ca/">StyledLink</StyledLink>
    <MenuSubheading>MenuSubheading</MenuSubheading>
    <MenuThirdHeading>MenuThirdHeading</MenuThirdHeading>
    <Price>Price</Price>
    <Copy>Copy</Copy>
  </>
)
