import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { Copy, RealWeddingsThirdHeading } from "./typography"

const VenueSummaryGrid = styled.div`
  width: 100%;
  margin: 20px 0;
  display: grid;
  grid-template-columns: repeat(2, auto);

  & > :nth-child(n + 3) {
    padding-top: 15px;
    border-top: 1px solid hsl(0, 0%, 20%);
  }

  & > * {
    h3 {
      margin-bottom: 0;
    }

    p {
      margin-bottom: 15px;
    }

    :nth-child(2n - 1) > * {
      border-right: 1px solid hsl(0, 0%, 20%);
      padding-right: 15px;
    }

    :nth-child(2n) > * {
      margin-left: 15px;
    }
  }

  & > :last-child > * {
    border: none;
  }

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(3, auto);

    & > :nth-child(3) {
      padding-top: 0;
      border-top: none;
    }

    & > * {
      :nth-child(3n - 2) > *,
      :nth-child(3n - 1) > * {
        border-right: 1px solid hsl(0, 0%, 20%);
        padding-right: 15px;
      }
      :nth-child(3n) > * {
        border: none;
        margin-left: 15px;
      }

      :last-child {
        grid-column: 1 / -1;

        & > * {
          border-right: none;
          margin-left: 0;
        }
      }
    }
  }

  @media screen and (min-width: 992px) {
    margin: 0;

    & > :last-child > p {
      margin-bottom: 0;
    }
  }
`

const VenueSummaryGridComponent = ({
  capacity,
  squareFootage,
  dimensions,
  extras,
}) => (
  <>
    {capacity || squareFootage || dimensions || extras ? (
      <VenueSummaryGrid>
        {[capacity, squareFootage, dimensions, extras].map((item, index) =>
          item ? (
            <div key={index}>
              <RealWeddingsThirdHeading>
                {capacity || squareFootage || dimensions
                  ? ["Capacity", "Area sq. ft.", "Dimensions", "Extras"][index]
                  : ""}
              </RealWeddingsThirdHeading>
              <Copy>{item}</Copy>
            </div>
          ) : null
        )}
      </VenueSummaryGrid>
    ) : null}
  </>
)

export default VenueSummaryGridComponent

VenueSummaryGridComponent.propTypes = {
  capacity: PropTypes.string,
  squareFootage: PropTypes.string,
  dimensions: PropTypes.string,
  extras: PropTypes.string,
}
