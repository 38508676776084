import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { animated, useSpring, useSprings, config } from "react-spring"
import useWindowSize from "../components/use-window-size"

import { MenuSubheading, RealWeddingsThirdHeading } from "./typography"

const Container = styled(animated.nav)`
  width: 100%;
  height: 58px;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  /* background-color: white; */

  display: grid;
  grid-template-rows: auto 1fr;

  @media screen and (min-width: 768px) {
    width: 79px;
    /* width: ${(props) =>
      props.fullHeight ? "calc(100% - 100px)" : "79px"}; */
    /* height: calc(var(--vh, 1vh) * 100); */
    /* transition: width 750ms cubic-bezier(0, 0.3, 0.3, 1); */
    grid-template-rows: 1fr;
    grid-template-columns: 79px 1fr 15%;

    transition: height 300ms ease-in-out;

    & > * {
      grid-row: 1;
    }
  }

  @media screen and (min-width: 1200px) {
    width: ${(props) => (props.fullHeight ? "calc(60%)" : "79px")};
    grid-template-columns: 79px 1fr 40%;
  }
`

const StaticNav = styled(animated.div)`
  width: calc(100% - 30px);
  height: 58px;
  z-index: 10;

  padding: 0 15px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;

  @media screen and (min-width: 768px) {
    width: 79px;
    height: inherit;
    padding: 0;

    display: grid;
    justify-content: center;
    justify-items: center;
    grid-template-rows: 44px auto 1fr auto 44px;

    & > * {
      grid-row: 2;
      grid-column: 1;
    }
  }
`

const LogoButton = styled.button`
  min-height: 44px;
  grid-row: 3;

  display: flex;
  align-items: center;

  & > :first-child {
    display: grid;
    align-items: center;
    justify-items: center;

    & > * {
      grid-row: 1;
      grid-column: 1;
    }
  }
`

const FullLogo = styled(animated.img)`
  width: 109px;
  height: 29px;
  opacity: 1;

  @media screen and (min-width: 768px) {
    /* display: none; */
    opacity: 0;
  }
`

const Favicon = styled(animated.img)`
  display: none;
  width: 44px;
  height: 44px;

  @media screen and (min-width: 768px) {
    display: unset;
    align-self: center;
  }
`

const HamburgerMenu = styled.button`
  width: 44px;
  height: 44px;

  & > svg {
    width: 44px;
    height: 44px;

    & > * {
      fill: none;
      stroke: hsl(0, 0%, 20%);
      stroke-width: 0.75px;
      stroke-miterlimit: 10;
    }
  }

  @media screen and (min-width: 768px) {
    align-self: start;
    grid-row: 2;
  }
`

const FlyOutMenu = styled.div`
  box-sizing: border-box;
  padding: 30px 15px;
  height: 100%;
  height: calc(100% - 15px);

  display: grid;
  grid-template-rows: 1fr auto;

  pointer-events: ${(props) => `${props.interactive ? "none" : "auto"}`};
  touch-action: ${(props) => `${props.interactive ? "none" : "auto"}`};
  overflow-y: scroll;

  @media screen and (min-width: 768px) {
    height: 100%;
    padding: 0 0 0 30px;
  }
`

const PageLinks = styled.ul`
  align-self: center;

  display: grid;
  row-gap: 15px;

  & > * {
    grid-column: 1;
    margin-left: 11px;
  }

  & > * > * {
    text-decoration: none;
  }
  & > * > * > h2 {
    margin-bottom: 0;
  }

  & > :first-child {
    margin-top: 60px;
  }

  & > :last-child {
    margin-bottom: 60px;
  }
`

const VenueLink = styled.div`
  display: grid;
  column-gap: 15px;
  align-items: center;
  grid-template-columns: 32px 1fr;
  margin-left: 15px;

  & > * {
    grid-row: 1;
  }

  & > :first-child {
    place-self: center;
    width: auto;
    height: 32px;
  }

  & > :nth-child(2) {
    margin-bottom: 0;
    transform: translateY(2px);
  }
`

const SocialMedia = styled.div`
  align-self: end;

  display: flex;
  flex-direction: row;

  & > a,
  & > a > :first-child {
    width: 44px;
    height: 44px;
  }

  & > * {
    margin-right: 11px;
  }

  & > :last-child {
    margin-right: 0;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
`

const DesktopSocialMedia = styled(animated.div)`
  display: none;

  @media screen and (min-width: 768px) {
    display: unset;
    grid-row: 4;
    align-self: end;

    display: flex;
    flex-direction: column;
    width: min-content;
    height: auto;

    & > a,
    & > a > :first-child {
      width: 44px;
      height: 44px;
    }
  }

  @media screen and (min-width: 992px) {
    /* & > a {
      margin-top: 11px;
    } */
  }
`

const Underlay = styled(animated.button)`
  display: none;

  @media screen and (min-width: 768px) {
    display: unset;
    width: 100%;
    height: 100%;
    grid-row: 1;
    grid-column: 3;
    background-color: hsl(0, 0%, 20%);
    opacity: 0.75;
    z-index: 0;
  }
`

const Navigation = () => {
  const {
    contentfulSiteData: {
      paradiseLogo: {
        description: paradiseLogoDescription,
        file: { url: paradiseLogoUrl },
      },
      monogram: {
        description: monogramDescription,
        file: { url: monogramUrl },
      },
    },
    contentfulNavigation: { links, socialMedia },
  } = useStaticQuery(
    graphql`
      query NavigationQuery {
        contentfulSiteData {
          paradiseLogo {
            description
            file {
              url
            }
          }
          monogram {
            description
            file {
              url
            }
          }
        }
        contentfulNavigation {
          links {
            ... on ContentfulPage {
              slug
              content {
                ... on ContentfulHeaderFullImageSection {
                  title
                }
              }
            }
            ... on ContentfulVenue {
              slug
              venueName
              venueGem {
                description
                file {
                  url
                }
                fluid {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
          socialMedia {
            name
            link
            icon {
              description
              file {
                url
              }
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    `
  )

  const [hamburgerMenuIsOpen, setHamburgerMenuIsOpen] = useState(false)
  const size = useWindowSize({ width: 0, height: 0 })

  const topLineProps = useSpring({
    reverse: !hamburgerMenuIsOpen,
    from: { x1: 10, y1: 15, x2: 34, y2: 15 },
    to: { x1: 13.5, y1: 13.5, x2: 30.5, y2: 30.5 },
    config: config.wobbly,
  })

  const midLineProps = useSpring({
    reverse: !hamburgerMenuIsOpen,
    from: { x1: 10, y1: 22, x2: 34, y2: 22 },
    to: { x1: 22, y1: 22, x2: 22, y2: 22 },
    config: config.stiff,
  })

  const bottomLineProps = useSpring({
    reverse: !hamburgerMenuIsOpen,
    from: { x1: 10, y1: 29, x2: 34, y2: 29 },
    to: { x1: 13.5, y1: 30.5, x2: 30.5, y2: 13.5 },
    config: config.wobbly,
  })

  // const oldFlyOutProps = useSpring({
  //   reverse: !hamburgerMenuIsOpen,
  //   from: { heightPercentage: 0 },
  //   to: { heightPercentage: 100 },
  //   config: size.width >= 768 ? config.default : config.default,
  //   delay: !hamburgerMenuIsOpen ? 100 : 0,
  // })

  const [flyOutProps, setFlyOutProps] = useSpring(() => ({
    heightPercentage: 0,
  }))

  const [linksSprings, setLinksSprings] = useSprings(links.length, () => ({
    opacity: hamburgerMenuIsOpen ? 1 : 0,
    x: hamburgerMenuIsOpen ? 0 : -100,
    config: config.gentle,
  }))

  const [socialMediaSprings, setSocialMediaSprings] = useSprings(
    socialMedia.length,
    () => ({
      opacity: hamburgerMenuIsOpen ? 1 : 0,
      x: hamburgerMenuIsOpen ? 0 : -100,
      config: config.gentle,
    })
  )

  const underlayProps = useSpring({
    reverse: !hamburgerMenuIsOpen,
    from: { opacity: 0, pointerEvents: "none", touchAction: "none" },
    to: { opacity: 0.15, pointerEvents: "auto", touchAction: "auto" },
    config: !hamburgerMenuIsOpen ? { duration: 100 } : config.slow,
    delay: !hamburgerMenuIsOpen ? 0 : 500,
  })

  const toggleNavigation = () => {
    setHamburgerMenuIsOpen(!hamburgerMenuIsOpen)
    setFlyOutProps({
      heightPercentage: !hamburgerMenuIsOpen ? 100 : 0,
      config: size.width >= 768 ? config.default : config.default,
      delay: !hamburgerMenuIsOpen ? 100 : 0,
    })
    setLinksSprings((index) => ({
      opacity: !hamburgerMenuIsOpen ? 1 : 0,
      x: !hamburgerMenuIsOpen ? 0 : -100,
      config: hamburgerMenuIsOpen ? { duration: 100 } : config.gentle,
      delay:
        size.width >= 768 && !hamburgerMenuIsOpen //&& size.height > 749
          ? 50 * index + 400
          : !hamburgerMenuIsOpen
          ? links[index].__typename === "ContentfulPage"
            ? 400 +
              links
                .filter((link) => link.__typename === "ContentfulPage")
                .indexOf(links[index]) *
                50
            : 0 // Math.exp(index / 5) * 100
          : 0,
    }))
    setSocialMediaSprings((index) => ({
      opacity: !hamburgerMenuIsOpen ? 1 : 0,
      x: !hamburgerMenuIsOpen ? 0 : -100,
      config: hamburgerMenuIsOpen ? { duration: 100 } : config.gentle,
      delay: !hamburgerMenuIsOpen ? Math.exp(index / 3) * 100 + 150 : 0,
    }))
  }

  return (
    <Container
      style={{
        background: flyOutProps.heightPercentage.interpolate((o) =>
          size.width >= 1200
            ? `linear-gradient(to right, hsla(0, 0%, 100%, ${o}%) 60%, hsla(0, 0%, 100%, 0) 60%)`
            : size.width >= 768
            ? `linear-gradient(to right, hsla(0, 0%, 100%, ${o}%) 85%, hsla(0, 0%, 100%, 0) 85%)`
            : `hsla(0, 0%, 100%, ${o}%)`
        ),
        width: flyOutProps.heightPercentage.interpolate((w) =>
          size ? (size.width >= 768 ? `${w}%` : "100%") : "auto"
        ),
        height:
          size.width < 768
            ? flyOutProps.heightPercentage.interpolate(
                (h) => `calc(${h}% + ${79 - (79 * h) / 100}px)`
              )
            : "calc(var(--vh, 1vh) * 100)",
        gridTemplateColumns: flyOutProps.heightPercentage.interpolate((o) =>
          size.width >= 1200
            ? `${79 + o}px 1fr 40%`
            : size.width >= 768
            ? `${79 + o}px 1fr 15%`
            : `auto`
        ),
      }}
    >
      <StaticNav
        style={{
          width: flyOutProps.heightPercentage.interpolate((w) =>
            size.width >= 768 ? `${w + 79}px` : null
          ),
        }}
      >
        <LogoButton>
          <Link
            to="/"
            onClick={() => {
              window
                ? window.location.pathname === `/`
                  ? toggleNavigation()
                  : null
                : null
            }}
          >
            <FullLogo
              style={{
                opacity: flyOutProps.heightPercentage.interpolate((h) =>
                  typeof size.width === "number"
                    ? size.width >= 768
                      ? `${h}%`
                      : "100%"
                    : null
                ),
              }}
              src={paradiseLogoUrl}
              alt={paradiseLogoDescription}
            />
            <Favicon
              style={{
                opacity: flyOutProps.heightPercentage.interpolate(
                  (h) => `${100 - h}%`
                ),
              }}
              src={monogramUrl}
              alt={monogramDescription}
            />
          </Link>
        </LogoButton>
        <HamburgerMenu aria-label="Hamburger Menu" onClick={toggleNavigation}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="44"
            viewBox="0 0 44 44"
          >
            <animated.line
              x1={topLineProps.x1}
              y1={topLineProps.y1}
              x2={topLineProps.x2}
              y2={topLineProps.y2}
            />
            <animated.line
              x1={midLineProps.x1}
              y1={midLineProps.y1}
              x2={midLineProps.x2}
              y2={midLineProps.y2}
            />
            <animated.line
              x1={bottomLineProps.x1}
              y1={bottomLineProps.y1}
              x2={bottomLineProps.x2}
              y2={bottomLineProps.y2}
            />
          </svg>
        </HamburgerMenu>
        <DesktopSocialMedia
          style={{
            transform: flyOutProps.heightPercentage.interpolate((h) =>
              size.width >= 768 ? `rotate(${(h / 100) * 90}deg)` : "100%"
            ),
          }}
        >
          {socialMedia.map((socialMediaItem, index) =>
            socialMedia[index].link.startsWith("/") ? (
              <animated.div
                key={index}
                style={{
                  transform: flyOutProps.heightPercentage.interpolate(
                    (h) => `rotate(${(h / 100) * -90}deg)`
                  ),
                  marginTop: flyOutProps.heightPercentage.interpolate((h) =>
                    size.width >= 992 ? `${5 - (h / 100) * 5}px` : "0"
                  ),
                }}
              >
                <Link to={socialMedia[index].link}>
                  {socialMediaItem.icon.fluid ? (
                    <Img
                      fluid={socialMediaItem.icon.fluid}
                      alt={socialMediaItem.icon.description}
                    />
                  ) : (
                    <img
                      src={socialMediaItem.icon.file.url}
                      alt={socialMediaItem.icon.description}
                    />
                  )}
                </Link>
              </animated.div>
            ) : (
              <animated.a
                href={socialMedia[index].link}
                key={index}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  transform: flyOutProps.heightPercentage.interpolate(
                    (h) => `rotate(${(h / 100) * -90}deg)`
                  ),
                  marginTop: flyOutProps.heightPercentage.interpolate((h) =>
                    size.width >= 992 ? `${5 - (h / 100) * 5}px` : "0"
                  ),
                }}
              >
                {socialMediaItem.icon.fluid ? (
                  <Img
                    fluid={socialMediaItem.icon.fluid}
                    alt={socialMediaItem.icon.description}
                  />
                ) : (
                  <img
                    src={socialMediaItem.icon.file.url}
                    alt={socialMediaItem.icon.description}
                  />
                )}
              </animated.a>
            )
          )}
        </DesktopSocialMedia>
      </StaticNav>
      <animated.div
        style={{
          height: flyOutProps.heightPercentage.interpolate(
            (height) => `${size.width < 768 ? height : 100}%`
          ),
          pointerEvents: hamburgerMenuIsOpen ? "auto" : "none",
          touchAction: hamburgerMenuIsOpen ? "auto" : "none",
          display: flyOutProps.heightPercentage.interpolate(
            (height) => `${height < 5 ? "none" : "unset"}`
          ),
          visibility: flyOutProps.heightPercentage.interpolate(
            (height) => `${height < 5 ? "collapse" : "visible"}`
          ),
          zIndex: flyOutProps.heightPercentage.interpolate((w) =>
            w < 5 ? `0` : "10"
          ),
          overflowY: "scroll",
        }}
      >
        <FlyOutMenu interactive={!hamburgerMenuIsOpen}>
          <PageLinks>
            {linksSprings.map(({ opacity, x }, index) =>
              links[index].__typename === "ContentfulVenue" &&
              size.width < 768 ? null : ( // size.height <= 749 ||
                <animated.li
                  key={index}
                  style={{
                    opacity,
                    transform: x.interpolate((x) => `translateX(${x}px)`),
                  }}
                >
                  {links[index].__typename === "ContentfulVenue" ? (
                    <Link
                      to={`/venues/${links[index].slug}/`}
                      onClick={() => {
                        window
                          ? window.location.pathname ===
                            `/venues/${links[index].slug}/`
                            ? toggleNavigation()
                            : null
                          : null
                      }}
                    >
                      <VenueLink>
                        {links[index].venueGem ? (
                          links[index].venueGem.fluid ? (
                            <Img
                              fluid={links[index].venueGem.fluid}
                              alt={links[index].venueGem.description}
                            />
                          ) : links[index].venueGem.file.url ? (
                            <img
                              src={links[index].venueGem.file.url}
                              alt={links[index].venueGem.description}
                              style={{ width: "44px" }}
                            />
                          ) : null
                        ) : null}
                        <RealWeddingsThirdHeading key={index}>
                          {links[index].venueName}
                        </RealWeddingsThirdHeading>
                      </VenueLink>
                    </Link>
                  ) : links[index].__typename === "ContentfulPage" ? (
                    <Link
                      to={`/${links[index].slug}/`}
                      onClick={() => {
                        window
                          ? window.location.pathname ===
                            `/${links[index].slug}/`
                            ? toggleNavigation()
                            : null
                          : null
                      }}
                    >
                      {links[index].content.map((contentItem, index) =>
                        index ? null : (
                          <MenuSubheading
                            key={index}
                            style={{ marginTop: "0" }}
                          >
                            {contentItem.title}
                          </MenuSubheading>
                        )
                      )}
                    </Link>
                  ) : null}
                </animated.li>
              )
            )}
          </PageLinks>
          <SocialMedia>
            {socialMediaSprings.map(({ opacity, x }, index) =>
              socialMedia[index].link.startsWith("/") ? null : (
                <animated.a
                  href={socialMedia[index].link}
                  key={index}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    opacity,
                    transform: x.interpolate((x) => `translateY(${x}px)`),
                  }}
                >
                  {socialMedia[index].icon.fluid ? (
                    <Img
                      fluid={socialMedia[index].icon.fluid}
                      alt={socialMedia[index].icon.description}
                    />
                  ) : (
                    <img
                      src={socialMedia[index].icon.file.url}
                      alt={socialMedia[index].icon.description}
                    />
                  )}
                </animated.a>
              )
            )}
          </SocialMedia>
        </FlyOutMenu>
      </animated.div>
      <Underlay
        onClick={toggleNavigation}
        style={underlayProps}
        aria-label="Dismiss Navigation"
      />
    </Container>
  )
}

export default Navigation
