/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const {
    contentfulSiteData: {
      title: siteTitle,
      description: siteDescription,
      author: siteAuthor,
      openGraphImage: {
        file: { url: openGraphImageUrl },
      },
    },
  } = useStaticQuery(
    graphql`
      query SEOQuery {
        contentfulSiteData {
          title
          description
          author
          openGraphImage {
            file {
              url
            }
          }
        }
      }
    `
  )

  const metaDescription = description || siteDescription
  const metaTitle = title || siteTitle

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={`%s`} // titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        // {
        //   name: "google-site-verification",
        //   content: "_-_Vmx5Usg2NZURLAj2DHzFrWafUN1ofa3BB2UlEe4M",
        // },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:url`,
          content: "https://paradisebanquethalls.com",
        },
        {
          property: `og:image`,
          content: `https:${openGraphImageUrl}`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteAuthor,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en-CA`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
