import React, { useLayoutEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Navigation from "../components/navigation"
import Footer from "../components/footer"
import useWindowSize from "../components/use-window-size"

const Site = styled.div`
  display: grid;
  grid-template-rows: 58px auto auto;
  grid-template-columns: 1fr;

  & > * {
    grid-column: 1;
  }

  & > :nth-child(1) {
    grid-row: 1;
  }

  & > :nth-child(2) {
    grid-row: 2;
  }

  & > :nth-child(3) {
    grid-row: 3;
  }

  @media screen and (min-width: 768px) {
    grid-template-rows: auto;
    grid-template-columns: 79px 1fr;

    & > * {
      grid-column: 2;
    }

    & > :nth-child(1) {
      grid-column: 1;
      grid-row: 1 / span 2;
    }

    & > :nth-child(2) {
      grid-row: 1;
    }

    & > :nth-child(3) {
      grid-row: 2;
    }
  }
`
const SiteContent = styled.main`
  margin-bottom: 60px;

  @media screen and (min-width: 768px) {
    display: grid;
    row-gap: 60px;
  }

  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
    /* row-gap: 120px; */
    row-gap: 0;
    /* margin-bottom: 120px; */
    margin-bottom: 0;

    & > * {
      grid-column: 1 / -1;
    }
  }
`

const Layout = ({ children }) => {
  const size = useWindowSize()
  // const [windowHeight, setWindowHeight] = useState(0)

  // if (windowHeight !== 0) {
  //   setWindowHeight(size.height)
  // }

  useLayoutEffect(() => {
    const vh = parseFloat(window.innerHeight * 0.01).toFixed(2)
    // const vh = windowHeight
    //   ? windowHeight
    //   : parseFloat(window.innerHeight * 0.01).toFixed(2)
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }, [size])

  return (
    <Site>
      <Navigation />
      <SiteContent>{children}</SiteContent>
      <Footer />
    </Site>
  )
}

export default Layout

Layout.propTypes = {
  children: PropTypes.array,
}
