import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"
import useMeasure from "react-use-measure"
import useWindowSize from "./use-window-size"

import VenueSummaryGridComponent from "./venue-summary-grid"

import {
  Heading,
  Copy,
  StyledLink,
  Subheading,
  RealWeddingsThirdHeading,
} from "./typography"

const Header = styled.section`
  background-color: white;
  height: calc(var(--vh, 1vh) * 100 - 58px);
  height: ${(props) => `${props.windowHeight - 58}px`};
  max-height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  transition: height 300ms ease-in-out;

  & > * {
    grid-column: 1;
    grid-row: 1;
  }

  & > :first-child {
    height: calc(100% - 200px);
    height: ${(props) => `calc(100% - ${props.height - 100}px)`};
    width: 100%;
    object-fit: cover;
  }

  @media screen and (min-width: 768px) {
    height: calc(var(--vh, 1vh) * 100);
    height: ${(props) => `${props.windowHeight}px`};
    max-height: 100vh;
  }

  @media screen and (min-width: 992px) {
    margin-bottom: ${(props) => props.pathnameMargin};

    & > :first-child {
      height: ${(props) => `${props.windowHeight}px`};
      max-height: 100vh;
    }
  }
`

const TextContent = styled.div`
  align-self: end;
  justify-self: end;
  background-color: white;
  margin-left: ${(props) => `${props.right ? "15px" : "0"}`};
  margin-right: ${(props) => `${props.right ? "0" : "15px"}`};
  padding: 24px 25px 45px 25px;
  min-height: 150px;
  z-index: 1;
  display: grid;
  row-gap: 10px;

  & > a {
    display: inline-block;
    margin-top: 15px;
  }

  & > a:first-child {
    margin-top: 0;

    & > * {
      margin: 0;
    }
  }

  @media screen and (min-width: 768px) {
    width: calc(565px - 62px - 62px);
    padding: 62px 62px 100px;
    justify-self: ${(props) => `${props.right ? "end" : "start"}`};
    margin: 0;
  }

  @media screen and (min-width: 1200px) {
    width: calc(62px + 40ch);
    padding: 62px calc((100% - 40ch - 62px) / 4) 90px 62px;
  }
`

const TitleAndGemRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > :nth-child(1) {
    margin: 0;
  }

  & > :nth-child(2) {
    width: stretch;
    height: stretch;
    max-width: 60px;
    max-height: 80px;
  }
`

const DateBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */

  & > svg {
    margin-right: 10px;
    transform: translateY(-1px);
  }
`

const HeaderComponent = ({
  image,
  contentOnRight = true,
  title,
  text,
  link,
  linkText,
  venueGem,
  capacity,
  squareFootage,
  dimensions,
  extras,
  subtitle,
  date,
  backLink,
}) => {
  const [ref, { height }] = useMeasure()
  const size = useWindowSize()
  const [windowHeight, setWindowHeight] = useState(0)
  const [pathnameMargin, setPathnameMargin] = useState(0)

  if (windowHeight === 0) {
    setWindowHeight(size.height)
  }

  useEffect(
    () =>
      typeof window === "object"
        ? window.location.pathname !== "/"
          ? setPathnameMargin("60px")
          : undefined
        : undefined,
    []
  )

  return (
    <Header
      height={height}
      windowHeight={windowHeight ? windowHeight : size.height}
      pathnameMargin={pathnameMargin}
    >
      {image.fluid ? (
        <Img
          fluid={image.fluid}
          alt={image.description || "Background Image"}
        />
      ) : image.file.url ? (
        <img
          src={image.file.url}
          alt={image.description || "Background Image"}
        />
      ) : (
        <div />
      )}
      <TextContent ref={ref} right={contentOnRight}>
        {backLink ? (
          <Link to={backLink}>
            <RealWeddingsThirdHeading>← Back</RealWeddingsThirdHeading>
          </Link>
        ) : null}
        {title && venueGem ? (
          <TitleAndGemRow>
            <Heading>{title}</Heading>
            {venueGem.fluid ? (
              <Img
                fluid={venueGem.fluid}
                alt={venueGem.description || "Background Image"}
              />
            ) : venueGem.file.url ? (
              <img
                src={venueGem.file.url}
                alt={venueGem.description || "Background Image"}
              />
            ) : null}
          </TitleAndGemRow>
        ) : title ? (
          <Heading>{title}</Heading>
        ) : null}
        {subtitle ? <Subheading>{subtitle}</Subheading> : null}
        {date ? (
          <DateBlock>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 18"
              width="20"
              height="18"
            >
              <path
                fill="none"
                stroke="#000000"
                d="M17,17H3c-1.1,0-2-0.9-2-2V5c0-1.1,0.9-2,2-2h14c1.1,0,2,0.9,2,2v10C19,16.1,18.1,17,17,17z"
              />
              <line fill="none" stroke="#000000" x1="1" y1="6" x2="19" y2="6" />
              <line
                fill="none"
                stroke="#000000"
                strokeLinecap="round"
                x1="14"
                y1="3"
                x2="14"
                y2="1"
              />
              <line
                fill="none"
                stroke="#000000"
                strokeLinecap="round"
                x1="6"
                y1="3"
                x2="6"
                y2="1"
              />
            </svg>
            <Copy>{date}</Copy>
          </DateBlock>
        ) : null}
        {capacity || squareFootage || dimensions || extras ? (
          <VenueSummaryGridComponent
            capacity={capacity}
            squareFootage={squareFootage}
            dimensions={dimensions}
            extras={extras}
          />
        ) : null}
        {text
          ? text
              .split("\n")
              .map((line, index) => <Copy key={index}>{line}</Copy>)
          : null}
        {RegExp("^/.*/$|^/$").test(link) ? ( // link.match(^\/.*\/$|^\/$)
          <StyledLink to={link} state={
              typeof window !== "undefined" // && typeof document !== "undefined"
                ? {
                  referrer: document.location.pathname}
                : undefined // save the current location and pass with route push
          }>{linkText ? linkText : link}</StyledLink>
        ) : link ? (
          <StyledLink href={link}>{linkText ? linkText : link}</StyledLink>
        ) : null}
      </TextContent>
    </Header>
  )
}

export default HeaderComponent

HeaderComponent.propTypes = {
  image: PropTypes.shape({
    fluid: PropTypes.object,
    description: PropTypes.string.isRequired,
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  contentOnRight: PropTypes.bool,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  invertColours: PropTypes.bool,
  venueGem: PropTypes.shape({
    description: PropTypes.string.isRequired,
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
    fluid: PropTypes.object,
  }),
  capacity: PropTypes.string,
  squareFootage: PropTypes.string,
  dimensions: PropTypes.string,
  extras: PropTypes.string,
  subtitle: PropTypes.string,
  date: PropTypes.string,
  backLink: PropTypes.string,
}
